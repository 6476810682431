<template>
  <div>
    <!-- <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark"
        ><i class="mdi mdi-home-variant h2"></i
      ></router-link>
    </div> -->
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo.png"
                  alt=""
                  height="150"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo.png"
                  height="150"
                  alt="" 
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Minible.</p>
                </div>
                <div class="p-2 mt-4">
                  <form>
                    <div class="mb-3">
                      <label for="email">E-mail<strong>*</strong></label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model="formData.email"
                        placeholder="Enter E-mail"
                        required
                      />
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/auth/recoverpwd" class="text-muted">
                          Forgot password?</router-link
                        >
                      </div>
                      <label for="userpassword"
                        >Password<strong>*</strong></label
                      >
                      <input
                        type="password"
                        class="form-control"
                        id="userpassword"
                        v-model="formData.password"
                        placeholder="Enter password"
                        required
                      />
                    </div>

                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Remember me</label
                      >
                    </div>

                    <div class="mt-3 text-end">
                      <button
                        class="btn btn-primary w-sm waves-effect waves-light"
                        type="button"
                        @click="login()"
                      >
                        Log In
                      </button>
                    </div>

                    <!-- <div class="mt-4 text-center">
                      <p class="mb-0">
                        Don't have an account ?
                        <router-link
                          to="/auth/register-1"
                          class="fw-medium text-primary"
                        >
                          Signup now</router-link
                        >
                      </p>
                    </div>
                     -->
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} Estetik International. Crafted with
                <i
                  class="mdi mdi-heart text-danger"
                  style="color: #b96955 !important"
                ></i>
                by Estetik International
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";

import { POPUP } from "@/state/popup";
import auth from "@/state/giris";
// import { API } from "@/state/API";

export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    document.body.classList.add("authentication-bg");
    console.log(auth.getters.isAuthenticated)
  },
  data() {
    return {
      title: "Log in",
      formData: {},
    };
  },
  methods: {
    login() {
      var data;
      if (this.formData.email != undefined) {
        auth
          .dispatch("login", {
            email: this.formData.email,
            password: this.formData.password,
          })
          .then((response) => {
            console.log(response);
            this.$router.push("/");
          });
      } else {
        var timer = 2000;
        data = "Fill in the required fields!!!";
        POPUP.popupClickNot(data, timer);
      }
    },
  },
};
</script>
<style >
.authentication-bg {
  background-color: #f4f1f0 !important;
}
strong {
  color: rgba(239, 4, 4, 0.39);
}
</style> 
